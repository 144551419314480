import $http from "./index";
import { PLATFROM_CONFIG } from "../../public/config";
let base = PLATFROM_CONFIG.baseURL;
// export const getvipmanage = (data) => {
//     return $http.post(
//         `/app/info/vipmanage/back/getVipManage`,
//         data
//     );
// };
export const getvipmanage = (data) => {
  return $http.get(`/app/info/vipmanage/back/getvipmanage`, {
    params: data,
  });
};
export const getequity = () => {
  return $http.get(`/app/info/vipequity/back/geEquity`, {});
};
export const deleVipManage = (data) => {
  return $http.get(`/app/info/vipmanage/back/delete`, {
    params: data,
  });
};
export const VipManagegyId = (data) => {
  return $http.get(`/app/info/vipmanage/back/gyId`, {
    params: data,
  });
};
export const addVipManage = (data) => {
  return $http.post(`/app/info/vipmanage/back/new`, data);
};
export const updateVipManage = (data) => {
  return $http.post(`/app/info/vipmanage/back/update`, data);
};
export const addVipManagEquity = (data) => {
  return $http.post(`/app/info/vipmanage/back/setdescpic`, data);
};
